import { Coach } from 'src/models/coach'
import Player from 'src/models/player'
import {
  BenchmarkDetails,
  Progress,
  SummaryRound,
} from 'src/store/summarySlice'
import { Gender, PlayerType } from './constants'

export interface Round {
  uuid: string
  holes: Hole[]
  datePlayed: Date
  summary?: Summary
  state: RoundState
  tourName?: string
  courseName?: string
  roundNumber?: string
  roundType: RoundType
  tournamentName?: string
  numberOfHoles: HolesNumber
  updatedAt?: Date
  isSimulated?: boolean
}

export enum RoundType {
  Practice = 'practice',
  Tournament = 'tournament',
}

export enum WhereType {
  Outdoor = 'outdoor',
  Simulator = 'simulator',
}

export enum RoundState {
  Complete = 'complete',
  Incomplete = 'incomplete',
}

export enum StrategyState {
  Complete = 'complete',
  Incomplete = 'incomplete',
  Submitted = 'submitted',
}

export interface Summary {
  score: Score
  categories: Category[]
  strokesGained: StrokesGained
  recommendedBenchmarkId: string
  performanceIndicators: PerformanceIndicator[]
  focusAreas?: FocusArea[]
}

export interface Score {
  par: number
  total: number
  toPar: number
  average: number
  rollingAverage: number
}

export interface RootStrokesGained {
  putt: RootStrokesGainedValue
  short: RootStrokesGainedValue
  total: RootStrokesGainedValue
  drives: RootStrokesGainedValue
  approach: RootStrokesGainedValue
}

export interface RootStrokesGainedValue {
  progress: number
  benchmark: number
  overall: number | null
}

export interface StrokesGained {
  putt: StrokesGainedValue
  short: StrokesGainedValue
  total: StrokesGainedValue
  drives: StrokesGainedValue
  approach: StrokesGainedValue
}

export interface StrokesGainedValue {
  average: number
  benchmark: number
  overall: number | null
  rollingAverage: number | null
}

export interface RootCategory {
  label: string
  metricId: string
  progress: number
  benchmark: number
  type: CategoryType
  strokesGained: number
}

export interface Category {
  label: string
  average: number
  benchmark: number
  metricId: MetricId
  type: CategoryType
  strokesGained: number
  rollingAverage: number | null
}

export enum CategoryType {
  All = 'all',
  Putt = 'putt',
  Short = 'short',
  Drives = 'drives',
  Approach = 'approach',
}

export enum CategoryMetricIds {
  Total = 'M02',
  Drives = 'M03',
  Approach = 'M04',
  Short = 'M05',
  Putt = 'M06',
}

export interface PerformanceIndicator {
  value: number
  premium: boolean
  inverted: boolean
  benchmark: number
  category: MetricId | MetricId[]
  metricId: MetricId
  subcategories: MetricId[]
  measurement: Omit<Measurement, 'Meters'>
}

export interface ShotsDistribution {
  aggregate: ShotsDistributionAggregate
  breakdown: ShotsDistributionBreakdown
}

export interface ShotsDistributionItem {
  name: string
  percentage: number
  perShot: number | null
  averagePerRound: number | null
}

export interface ShotsDistributionAggregate {
  offTheTee: ShotsDistributionItem
  putting: ShotsDistributionItem
  approach: ShotsDistributionItem
  shortGame: ShotsDistributionItem
  totalStrokes: number
}

export interface ShotsDistributionBreakdown {
  offTheTee: OffTheTeeBreakdown
  approach: ApproachBreakdown
  shortGame: ShortGameBreakdown
  putting: PuttingBreakdown
  fairway: LieBreakdown
  rough: LieBreakdown
  deepRough: LieBreakdown
  recovery: LieBreakdown
  approach100to150: Approach100to150Breakdown
  approach150to200: Approach150to200Breakdown
  approach200to250: Approach200to250Breakdown
  approachOver250: ApproachOver250Breakdown
  shortLessThan20: ShortLessThan20Breakdown
  short20to60: Short20to60Breakdown
  short60to100: Short60to100Breakdown
  shortSand0to100: ShortSand0to100Breakdown
  puttingLessThan7: PuttingLessThan7Breakdown
  putting7to21: Putting7to21Breakdown
  puttingOver21: PuttingOver21Breakdown
}

export interface OffTheTeeBreakdown {
  metricId: string
  par4: ShotsDistributionItem | null
  par5: ShotsDistributionItem | null
}

export interface ApproachBreakdown {
  metricId: string
  from100to149: ShotsDistributionItem | null
  from150to199: ShotsDistributionItem | null
  from200to249: ShotsDistributionItem | null
  over250: ShotsDistributionItem | null
}

export interface ShortGameBreakdown {
  metricId: string
  lessThan20: ShotsDistributionItem | null
  from20to59: ShotsDistributionItem | null
  from60to99: ShotsDistributionItem | null
  sand: ShotsDistributionItem | null
}

export interface PuttingBreakdown {
  metricId: string
  one: number | null
  two: number | null
  three: number | null
  four: number | null
  five: number | null
  six: number | null
  seven: number | null
  eight: number | null
  nine: number | null
  ten: number | null
  lessThan7: ShotsDistributionItem | null
  from7To20: ShotsDistributionItem | null
  over21: ShotsDistributionItem | null
  from5To10: number | null
  from10to15: number | null
  from15To20: number | null
  from20To25: number | null
  over25: number | null
}

export interface LieBreakdown {
  from0to9: number | null
  from10to19: number | null
  from20to29: number | null
  from30to39: number | null
  from40to49: number | null
  from50to59: number | null
  from60to69: number | null
  from70to79: number | null
  from80to89: number | null
  from90to99: number | null
  from100to124: number | null
  from125to149: number | null
  from150to174: number | null
  from175to199: number | null
  from200to224: number | null
  from225to249: number | null
  from250to274: number | null
  over275: number | null
}

export interface Approach100to150Breakdown {
  fairway100to124: ShotsDistributionItem | null
  rough100to124: ShotsDistributionItem | null
  deepRough100to124: ShotsDistributionItem | null
  fairway125to149: ShotsDistributionItem | null
  rough125to149: ShotsDistributionItem | null
  deepRough125to149: ShotsDistributionItem | null
}

export interface Approach150to200Breakdown {
  fairway150to174: ShotsDistributionItem | null
  rough150to174: ShotsDistributionItem | null
  deepRough150to174: ShotsDistributionItem | null
  fairway175to199: ShotsDistributionItem | null
  rough175to199: ShotsDistributionItem | null
  deepRough175to199: ShotsDistributionItem | null
}

export interface Approach200to250Breakdown {
  fairway200to224: ShotsDistributionItem | null
  rough200to224: ShotsDistributionItem | null
  deepRough200to224: ShotsDistributionItem | null
  fairway225to249: ShotsDistributionItem | null
  rough225to249: ShotsDistributionItem | null
  deepRough225to249: ShotsDistributionItem | null
}

export interface ApproachOver250Breakdown {
  fairway250to274: ShotsDistributionItem | null
  rough250to274: ShotsDistributionItem | null
  deepRough250to274: ShotsDistributionItem | null
  fairwayOver275: ShotsDistributionItem | null
  roughOver275: ShotsDistributionItem | null
  deepRoughOver275: ShotsDistributionItem | null
}

export interface ShortLessThan20Breakdown {
  fairway0to9: ShotsDistributionItem | null
  rough0to9: ShotsDistributionItem | null
  deepRough0to9: ShotsDistributionItem | null
  fairway10to19: ShotsDistributionItem | null
  rough10to19: ShotsDistributionItem | null
  deepRough10to19: ShotsDistributionItem | null
}

export interface Short20to60Breakdown {
  fairway20to39: ShotsDistributionItem | null
  rough20to39: ShotsDistributionItem | null
  deepRough20to39: ShotsDistributionItem | null
  fairway40to59: ShotsDistributionItem | null
  rough40to59: ShotsDistributionItem | null
  deepRough40to59: ShotsDistributionItem | null
}

export interface Short60to100Breakdown {
  fairway60to79: ShotsDistributionItem | null
  rough60to79: ShotsDistributionItem | null
  deepRough60to79: ShotsDistributionItem | null
  fairway80to99: ShotsDistributionItem | null
  rough80to99: ShotsDistributionItem | null
  deepRough80to99: ShotsDistributionItem | null
}

export interface ShortSand0to100Breakdown {
  sand0to29: ShotsDistributionItem | null
  sand30to59: ShotsDistributionItem | null
  sand60to99: ShotsDistributionItem | null
}

export interface PuttingLessThan7Breakdown {
  putting1: ShotsDistributionItem | null
  putting2: ShotsDistributionItem | null
  putting3: ShotsDistributionItem | null
  putting4: ShotsDistributionItem | null
  putting5: ShotsDistributionItem | null
  putting6: ShotsDistributionItem | null
}

export interface Putting7to21Breakdown {
  putting5to10: ShotsDistributionItem | null
  putting10to15: ShotsDistributionItem | null
  putting15to20: ShotsDistributionItem | null
  putting20to25: ShotsDistributionItem | null
}

export interface PuttingOver21Breakdown {
  putting25to30: ShotsDistributionItem | null
  putting30to35: ShotsDistributionItem | null
  putting35to40: ShotsDistributionItem | null
  putting40to45: ShotsDistributionItem | null
  putting45to50: ShotsDistributionItem | null
  puttingOver50: ShotsDistributionItem | null
}

export enum Measurement {
  MPH = 'mph',
  Feet = 'feet',
  Score = 'score',
  Yards = 'yards',
  Number = 'number',
  Meters = 'meters',
  StrokesGained = 'sg',
  Percentage = 'percent',
  PuttsOfTen = 'putts_out_of_ten',
}

export enum ReferencePoint {
  PGA = 'pga',
  LPGA = 'lpga',
  MaleScratch = 'male_scratch',
  FemaleScratch = 'female_scratch',
}

export interface IO {
  metricId: MetricId
  difference: null | number
}

export interface FocusArea {
  metricId: MetricId
  metricName: string
  difference: number
}

export interface FocusAreaIO {
  metricId: MetricId
  difference: number
}

export interface Hole {
  par: Par
  shots: Shot[]
}

export interface Shot {
  uuid?: number
  lie: Lie
  distance: string
  isLayup?: boolean
}

export enum ReportGeneration {
  Manual = 'manual',
  Automatic = 'automatic',
}

export enum ReportType {
  Evaluation = 'Evaluation',
  MonthlyReport = 'MonthlyReport',
  DiscussionDocument = 'DiscussionDocument',
}

export enum ReportGenerationStatus {
  InProgress = 'InProgress',
  Complete = 'Complete',
  Error = 'Error',
}
export interface ReportListItem {
  reportUuid: string
  title: string
  generatedDate: Date
  coachName: string
  generationType: ReportGeneration
  status: ReportGenerationStatus
}

export interface ReportInfo {
  reportUUID: string
  title: string
  coachName: string
  createdAt: Date
  generationType: ReportGeneration
  type: ReportType
  status: ReportGenerationStatus
}

export interface ReportComments {
  general: string
  practicePlan: string
  [key: string]: string
}

export interface Report {
  uuid: string
  createdAt: Date
  title: string
  version: string
  generationType: ReportGeneration
  data: {
    player: {
      gender: Gender
      playerType: PlayerType
      benchmarkId: BenchmarkId
    }
    summary: {
      totalSummary: {
        progressDonut: Progress
        focusAreas: FocusAreaIO[]
        categories: RootCategory[]
        strokesGained: RootStrokesGained
        performanceIndicators: PerformanceIndicator[]
      }
      rounds: SummaryRound[]
      auxRounds: SummaryRound[]
      benchmarkDetails: BenchmarkDetails
    }
    io: {
      savedMetrics: IO[]
      suggestMetrics: IO[]
      focusAreas: FocusAreaIO[]
    }
  }
  comments?: ReportComments
  coach: Coach
  player: Player & { email: string }
}

export interface MonthlyReport {
  startDate: number
  endDate: number
  player: Player & { email: string }
  createdAt: Date
  title: string
}

export interface ForecastItem {
  date: Date
  week: number
  stats: {
    M02: number | null
    M03: number | null
    M04: number | null
    M05: number | null
    M06: number | null
  }
}

export enum HolesNumber {
  Nine = 'nine',
  Eighteen = 'eighteen',
}

export enum Par {
  Three = 'three',
  Four = 'four',
  Five = 'five',
}

export enum Lie {
  Tee = 'tee',
  TeeDrive = 'tee_driver',
  TeeLayup = 'tee_layup',
  Sand = 'sand',
  Rough = 'rough',
  Green = 'green',
  Fairway = 'fairway',
  Penalty = 'penalty',
  Recovery = 'recovery',
  DeepRough = 'deep_rough',
}

export enum MetricId {
  Score = 'M01',
  All = 'M02',
  Drives = 'M03',
  Approach = 'M04',
  Short = 'M05',
  Putt = 'M06',
  DrivesPar4 = 'M07',
  DrivesPar5 = 'M08',
  Approach101To150 = 'M09',
  Approach151To200 = 'M10',
  Approach201To250 = 'M11',
  ApproachOver250 = 'M12',
  ShortUnder20 = 'M13',
  Short21To60 = 'M14',
  Short61To100 = 'M15',
  ShortBunkers = 'M16',
  PuttUnder6 = 'M17',
  Putt7To21 = 'M18',
  PuttOver21 = 'M19',
  DrivingDistance = 'M20',
  FairwaysHit = 'M21',
  GIR = 'M22',
  SandSaves = 'M23',
  BirdieOrBetterNumber = 'M24',
  ParsPerRound = 'M25',
  BogeyOrWorse = 'M26',
  BirdieOrBetterPercentage = 'M27',
  Par3BirdieOrBetterPercentage = 'M28',
  Par4BirdieOrBetterPercentage = 'M29',
  Par5BirdieOrBetterPercentage = 'M30',
  BirdieConversion = 'M31',
  BogeyOrWorsePercentage = 'M32',
  Par3Score = 'M33',
  Par4Score = 'M34',
  Par5Score = 'M35',
  PuttsPerHole = 'M36',
  PuttsPerGIR = 'M37',
  TotalPuttDistance = 'M38',
  PenaltyPerRound = 'M39',
  DrivesPerShot = 'M40',
  ApproachOver100PerShot = 'M41',
  ShortGameUnder100PerShot = 'M42',
  PuttingPerShot = 'M43',
  DrivesPar4PerShot = 'M44',
  DrivesPar5PerShot = 'M45',
  Approach100To150PerShot = 'M46',
  Approach150To200PerShot = 'M47',
  Approach200To250PerShot = 'M48',
  ApproachOver250PerShot = 'M49',
  ShortUnder20PerShot = 'M50',
  Short20To60PerShot = 'M51',
  Short60To100PerShot = 'M52',
  ShortBunkersPerShot = 'M53',
  PuttUnder6PerShot = 'M54',
  Putt7To11PerShot = 'M55',
  PuttOver21PerShot = 'M56',
  GreenFromUnder75 = 'M57',
  Green75To100 = 'M58',
  Green100To125 = 'M59',
  Green125To150 = 'M60',
  Green150To175 = 'M61',
  Green175To200 = 'M62',
  GreenOver200 = 'M63',
  Proximity50To75 = 'M64',
  Proximity75To100 = 'M65',
  Proximity100To125 = 'M66',
  Proximity125To150 = 'M67',
  Proximity150To175 = 'M68',
  Proximity175To200 = 'M69',
  Proximity200To225 = 'M70',
  Proximity225To250 = 'M71',
  ProximityBunkers = 'M72',
  Scrambling = 'M73',
  ScramblingUnder10 = 'M74',
  Scrambling10To20 = 'M75',
  Scrambling20To30 = 'M76',
  ScramblingOver30 = 'M77',
  OnePuttRate = 'M78',
  PuttsMadeUnder5 = 'M79',
  PuttsMade5To10 = 'M80',
  PuttsMade10To15 = 'M81',
  PuttsMade15To20 = 'M82',
  PuttsMade20To25 = 'M83',
  PuttsMadeOver25 = 'M84',
  ThreePuttRate = 'M85',
  ThreePuttRateUnder5 = 'M86',
  ThreePuttRate5To10 = 'M87',
  ThreePuttRate10To15 = 'M88',
  ThreePuttRate15To20 = 'M89',
  ThreePuttRate20To25 = 'M90',
  ThreePuttRateOver25 = 'M91',
  Putt12To16PerShot = 'M92',
  Putt17To21PerShot = 'M93',
  AverageYardsCoveredByDrivesPar4 = 'M94',
  AverageYardsCoveredByDrivesPar5 = 'M95',
  Proximity50To75Rough = 'M96',
  Proximity75To100Rough = 'M97',
  Proximity100To125Rough = 'M98',
  Proximity125To150Rough = 'M99',
  Proximity150To175Rough = 'M100',
  Proximity175To200Rough = 'M101',
  Proximity200To225Rough = 'M102',
  Proximity225To250Rough = 'M103',
  Proximity50To75DeepRough = 'M104',
  Proximity75To100DeepRough = 'M105',
  Proximity100To125DeepRough = 'M106',
  Proximity125To150DeepRough = 'M107',
  Proximity150To175DeepRough = 'M108',
  Proximity175To200DeepRough = 'M109',
  Proximity0To10 = 'M110',
  Proximity10To20 = 'M111',
  Proximity20To30 = 'M112',
  Proximity30To50 = 'M113',
  Proximity0To10Rough = 'M114',
  Proximity10To20Rough = 'M115',
  Proximity20To30Rough = 'M116',
  Proximity30To50Rough = 'M117',
  FairwaysHitPar4 = 'M118',
  FairwaysHitPar5 = 'M119',
  OnePuttRate1 = 'M120',
  OnePuttRate2 = 'M121',
  OnePuttRate3 = 'M122',
  OnePuttRate4 = 'M123',
  OnePuttRate5 = 'M124',
  OnePuttRate6 = 'M125',
  OnePuttRate7 = 'M126',
  OnePuttRate8 = 'M127',
  OnePuttRate9 = 'M128',
  OnePuttRate0 = 'M129',
  RecoveryStrikesPerRound = 'M130',
  AverageSecondPuttDistanceFirstOver10 = 'M131',
  AverageYardsCoveredByDrivesNoLayups = 'M132',
  FairwaysHitDriver = 'M133',
  FairwaysHitLayup = 'M134',
  Par4LayupStrokesGained = 'M135',
  Par4DriverStrokesGained = 'M136',
  DriverPenaltyRate = 'M137',
  DriverRecoveryRate = 'M138',
  PenaltyDrivesPar4Rate = 'M139',
  PenaltyDrivesPar5Rate = 'M140',
  RecoveryDrivesPar4Rate = 'M141',
  RecoveryDrivesPar5Rate = 'M142',
  ApproachPenaltyRate100To124 = 'M143',
  ApproachPenaltyRate125To149 = 'M144',
  ApproachPenaltyRate150To174 = 'M145',
  ApproachPenaltyRate175To199 = 'M146',
  ApproachPenaltyRate200To224 = 'M147',
  ApproachPenaltyRate225To249 = 'M148',
  ApproachPenaltyRate250To274 = 'M149',
  ApproachPenaltyRateOver275 = 'M150',
  ApproachRecoveryRate100To124 = 'M151',
  ApproachRecoveryRate125To149 = 'M152',
  ApproachRecoveryRate150To174 = 'M153',
  ApproachRecoveryRate175To199 = 'M154',
  ApproachRecoveryRate200To224 = 'M155',
  ApproachRecoveryRate225To249 = 'M156',
  ApproachRecoveryRate250To274 = 'M157',
  ApproachRecoveryRateOver275 = 'M158',
  ApproachOutOfPlayRate100To124 = 'M159',
  ApproachOutOfPlayRate125To149 = 'M160',
  ApproachOutOfPlayRate150To174 = 'M161',
  ApproachOutOfPlayRate175To199 = 'M162',
  ApproachOutOfPlayRate200To224 = 'M163',
  ApproachOutOfPlayRate225To249 = 'M164',
  ApproachOutOfPlayRate250To274 = 'M165',
  ApproachOutOfPlayRateOver275 = 'M166',
  DriverOutOfPlayRate = 'M167',
  OutOfPlayDrivesPar4Rate = 'M168',
  OutOfPlayDrivesPar5Rate = 'M169',
  OutOfPlayDrivesPar4DriverRate = 'M170',
  OutOfPlayDrivesPar4LayupRate = 'M171',
  OutOfPlayDrivesPar5DriverRate = 'M172',
  OutOfPlayDrivesPar5LayupRate = 'M173',
  AverageYardsCoveredByDrivesPar4Driver = 'M174',
  AverageYardsCoveredByDrivesPar4Layup = 'M175',
  AverageYardsCoveredByDrivesPar5Driver = 'M176',
  AverageYardsCoveredByDrivesPar5Layup = 'M177',
  SecondPuttAsPercentOfFirstDistance7To10 = 'M178',
  SecondPuttAsPercentOfFirstDistance10To21 = 'M179',
  SecondPuttAsPercentOfFirstDistanceOver21 = 'M180',
  SecondPuttAsPercentOfFirstDistance10To15 = 'M181',
  SecondPuttAsPercentOfFirstDistance15To20 = 'M182',
  SecondPuttAsPercentOfFirstDistance20To25 = 'M183',
  SecondPuttAsPercentOfFirstDistance25To30 = 'M184',
  SecondPuttAsPercentOfFirstDistance30To35 = 'M185',
  SecondPuttAsPercentOfFirstDistance35To40 = 'M186',
  SecondPuttAsPercentOfFirstDistance40To45 = 'M187',
  SecondPuttAsPercentOfFirstDistance45To50 = 'M188',
  SecondPuttAsPercentOfFirstDistanceOver50 = 'M189',
  AvgSecondPuttDistanceFirstDistance7To10 = 'M190',
  AvgSecondPuttDistanceFirstDistance10To21 = 'M191',
  AvgSecondPuttDistanceFirstDistanceOver21 = 'M192',
  AvgSecondPuttDistanceFirstDistance10To15 = 'M193',
  AvgSecondPuttDistanceFirstDistance15To20 = 'M194',
  AvgSecondPuttDistanceFirstDistance20To25 = 'M195',
  AvgSecondPuttDistanceFirstDistance25To30 = 'M196',
  AvgSecondPuttDistanceFirstDistance30To35 = 'M197',
  AvgSecondPuttDistanceFirstDistance35To40 = 'M198',
  AvgSecondPuttDistanceFirstDistance40To45 = 'M199',
  AvgSecondPuttDistanceFirstDistance45To50 = 'M200',
  AvgSecondPuttDistanceFirstDistanceOver50 = 'M201',
  ProximitySand30To60 = 'M202',
  ProximitySand60To100 = 'M203',
  onGreenFairway0to75 = 'M204',
  onGreenFairway75to100 = 'M205',
  onGreenFairway100to125 = 'M206',
  onGreenFairway125to150 = 'M207',
  onGreenFairway150to175 = 'M208',
  onGreenFairway175to200 = 'M209',
  onGreenFairwayOver200 = 'M210',
  onGreenRough0to75 = 'M211',
  onGreenRough75to100 = 'M212',
  onGreenRough100to125 = 'M213',
  onGreenRough125to150 = 'M214',
  onGreenRough150to175 = 'M215',
  onGreenRough175to200 = 'M216',
  onGreenRoughOver200 = 'M217',
  onGreenDeepRough0to75 = 'M218',
  onGreenDeepRough75to100 = 'M219',
  onGreenDeepRough100to125 = 'M220',
  onGreenDeepRough125to150 = 'M221',
  onGreenDeepRough150to175 = 'M222',
  onGreenDeepRough175to200 = 'M223',
  GirPar3Percentage = 'M224',
  GirPar4Percentage = 'M225',
  GirPar5Percentage = 'M226',
  proximityFrom0To10DeepRough = 'M227',
  proximityFrom10To20DeepRough = 'M228',
  proximityFrom20To30DeepRough = 'M229',
  proximityFrom30To50DeepRough = 'M230',
  from200To225 = 'M231',
  from225To250 = 'M232',
  from200To225Fairway = 'M233',
  from225To250Fairway = 'M234',
  from200To225Rough = 'M235',
  from225To250Rough = 'M236',
  onGreenFrom200To225DeepRough = 'M237',
  onGreenFrom225To250DeepRough = 'M238',
  fromLessThan10Fairway = 'M239',
  from10To20Fairway = 'M240',
  from20To30Fairway = 'M241',
  greaterThan30Fairway = 'M242',
  fromLessThan10Rough = 'M243',
  from10To20Rough = 'M244',
  from20To30Rough = 'M245',
  greaterThan30Rough = 'M246',
  fromLessThan10DeepRough = 'M247',
  from10To20DeepRough = 'M248',
  from20To30DeepRough = 'M249',
  greaterThan30DeepRough = 'M250',
  fromLessThan10Sand = 'M251',
  from10To20Sand = 'M252',
  from20To30Sand = 'M253',
  greaterThan30Sand = 'M254',
  from200To225DeepRough = 'M255',
  from225To250DeepRough = 'M256',
}

export const improvementOpportunities = [
  MetricId.Drives,
  MetricId.Putt7To21,
  MetricId.PuttOver21,
  MetricId.PuttUnder6,
  MetricId.Short21To60,
  MetricId.ShortUnder20,
  MetricId.Short61To100,
  MetricId.ShortBunkers,
  MetricId.ApproachOver250,
  MetricId.Approach101To150,
  MetricId.Approach151To200,
  MetricId.Approach201To250,
]

export const perShotMetrics = [
  MetricId.DrivesPerShot,
  MetricId.ApproachOver100PerShot,
  MetricId.ShortGameUnder100PerShot,
  MetricId.PuttingPerShot,
  MetricId.DrivesPar4PerShot,
  MetricId.DrivesPar5PerShot,
  MetricId.Approach100To150PerShot,
  MetricId.Approach150To200PerShot,
  MetricId.Approach200To250PerShot,
  MetricId.ApproachOver250PerShot,
  MetricId.ShortUnder20PerShot,
  MetricId.Short20To60PerShot,
  MetricId.Short60To100PerShot,
  MetricId.ShortBunkersPerShot,
  MetricId.PuttUnder6PerShot,
  MetricId.Putt7To11PerShot,
  MetricId.Putt12To16PerShot,
  MetricId.Putt17To21PerShot,
  MetricId.PuttOver21PerShot,
]

export const CATEGORY_TYPE_MAP = {
  [MetricId.DrivesPar4]: CategoryType.Drives,
  [MetricId.DrivesPar5]: CategoryType.Drives,
  [MetricId.Par4LayupStrokesGained]: CategoryType.Drives,
  [MetricId.Par4DriverStrokesGained]: CategoryType.Drives,
  [MetricId.Approach101To150]: CategoryType.Approach,
  [MetricId.Approach151To200]: CategoryType.Approach,
  [MetricId.Approach201To250]: CategoryType.Approach,
  [MetricId.ApproachOver250]: CategoryType.Approach,
  [MetricId.ShortUnder20]: CategoryType.Short,
  [MetricId.Short21To60]: CategoryType.Short,
  [MetricId.Short61To100]: CategoryType.Short,
  [MetricId.ShortBunkers]: CategoryType.Short,
  [MetricId.PuttUnder6]: CategoryType.Putt,
  [MetricId.Putt7To21]: CategoryType.Putt,
  [MetricId.PuttOver21]: CategoryType.Putt,
}

export enum BenchmarkId {
  MaleScratch = 'B01',
  MalePGAAvg = 'B02',
  MaleTop35 = 'B03',
  MaleTop25 = 'B04',
  MaleTop10 = 'B05',
  MaleWinner = 'B06',
  MaleIOGTop30 = 'B07',
  FemaleScratch = 'B08',
  FemalePGAAvg = 'B09',
  FemaleTop35 = 'B10',
  FemaleTop25 = 'B11',
  FemaleTop10 = 'B12',
  FemaleWinner = 'B13',
  FemaleIOGTop30 = 'B14',
  MaleProReady = 'B15',
  FemaleProReady = 'B16',
  MaleNCAADivisionIIIAvg = 'B17',
  MaleNCAADivisionIIITop20 = 'B18',
  MaleNCAADivisionIIITop10 = 'B19',
  MaleNCAADivisionIIAvg = 'B20',
  MaleNCAADivisionIITop20 = 'B21',
  MaleNCAADivisionIITop10 = 'B22',
  MaleNCAADivisionIAvg = 'B23',
  MaleNCAADivisionITop20 = 'B24',
  MaleNCAADivisionITop10 = 'B25',
  FemaleNCAADivisionIIIAvg = 'B26',
  FemaleNCAADivisionIIITop20 = 'B27',
  FemaleNCAADivisionIIITop10 = 'B28',
  FemaleNCAADivisionIIAvg = 'B29',
  FemaleNCAADivisionIITop20 = 'B30',
  FemaleNCAADivisionIITop10 = 'B31',
  FemaleNCAADivisionIAvg = 'B32',
  FemaleNCAADivisionITop20 = 'B33',
  FemaleNCAADivisionITop10 = 'B34',
  BelowScratch = 'below_scratch',
}

export const FEMALE_SCRATCH_BENCHMARKS = [BenchmarkId.FemaleScratch]

export const MALE_SCRATCH_BENCHMARKS = [BenchmarkId.MaleScratch]

export const MALE_PRO_REFERENCED_BENCHMARKS = [
  BenchmarkId.MalePGAAvg,
  BenchmarkId.MaleTop35,
  BenchmarkId.MaleTop25,
  BenchmarkId.MaleTop10,
  BenchmarkId.MaleWinner,
  BenchmarkId.MaleIOGTop30,
  BenchmarkId.MaleProReady,
  BenchmarkId.MaleNCAADivisionIIIAvg,
  BenchmarkId.MaleNCAADivisionIIITop20,
  BenchmarkId.MaleNCAADivisionIIITop10,
  BenchmarkId.MaleNCAADivisionIIAvg,
  BenchmarkId.MaleNCAADivisionIITop20,
  BenchmarkId.MaleNCAADivisionIITop10,
  BenchmarkId.MaleNCAADivisionIAvg,
  BenchmarkId.MaleNCAADivisionITop20,
  BenchmarkId.MaleNCAADivisionITop10,
]

export const MALE_PRO_BENCHMARK_SELECTION = [
  BenchmarkId.MalePGAAvg,
  BenchmarkId.MaleTop35,
  BenchmarkId.MaleTop25,
  BenchmarkId.MaleTop10,
  BenchmarkId.MaleWinner,
  BenchmarkId.MaleProReady,
  BenchmarkId.MaleNCAADivisionIIIAvg,
  BenchmarkId.MaleNCAADivisionIIAvg,
  BenchmarkId.MaleNCAADivisionIAvg,
]

export const FEMALE_PRO_REFERENCED_BENCHMARKS = [
  BenchmarkId.FemalePGAAvg,
  BenchmarkId.FemaleTop35,
  BenchmarkId.FemaleTop25,
  BenchmarkId.FemaleTop10,
  BenchmarkId.FemaleWinner,
  BenchmarkId.FemaleIOGTop30,
  BenchmarkId.FemaleProReady,
  BenchmarkId.FemaleNCAADivisionIIIAvg,
  BenchmarkId.FemaleNCAADivisionIIITop20,
  BenchmarkId.FemaleNCAADivisionIIITop10,
  BenchmarkId.FemaleNCAADivisionIIAvg,
  BenchmarkId.FemaleNCAADivisionIITop20,
  BenchmarkId.FemaleNCAADivisionIITop10,
  BenchmarkId.FemaleNCAADivisionIAvg,
  BenchmarkId.FemaleNCAADivisionITop20,
  BenchmarkId.FemaleNCAADivisionITop10,
]

export const FEMALE_PRO_BENCHMARK_SELECTION = [
  BenchmarkId.FemalePGAAvg,
  BenchmarkId.FemaleTop35,
  BenchmarkId.FemaleTop25,
  BenchmarkId.FemaleTop10,
  BenchmarkId.FemaleWinner,
  BenchmarkId.FemaleProReady,
  BenchmarkId.FemaleNCAADivisionIIIAvg,
  BenchmarkId.FemaleNCAADivisionIIAvg,
  BenchmarkId.FemaleNCAADivisionIAvg,
]

export interface Practice {
  date: Date
  id: number
  uuid: string
  updateAt: Date
  createdAt: Date
  hasSubmitted: boolean
  activities: Activity[]
  goal: { benchmarkId: string }
}

export interface Activity {
  go?: ValueGo
  focus?: boolean
  unit: Measurement
  input: null | number
  activityId: ActivityId
  category: CategoryType
  target?: null | number
  currentValue?: null | number
  inputDifference?: null | number
  activityName?: string | null
}

export enum ValueGo {
  Above = 'above',
  Below = 'below',
}

export enum NinePlayed {
  Front = 'front',
  Back = 'back',
}

export enum ActivityId {
  DrivingDistance = 'A01',
  DistanceAccuracy = 'A02',
  ClubHeadSpeed = 'A03',
  ProxToHole50To75Fairway = 'A04',
  ProxToHole75To100Fairway = 'A05',
  ProxToHole100To125Fairway = 'A06',
  ProxToHole125To150Fairway = 'A07',
  ProxToHole150To175Fairway = 'A08',
  ProxToHole175To200Fairway = 'A09',
  ProxToHole200To225Fairway = 'A10',
  ProxToHole225To250Fairway = 'A11',
  ProxToHole250To275Fairway = 'A12',
  ProxToHoleOver275Fairway = 'A13',
  ProxToHole50To75Rough = 'A14',
  ProxToHole75To100Rough = 'A15',
  ProxToHole100To125Rough = 'A16',
  ProxToHole125To150Rough = 'A17',
  ProxToHole150To175Rough = 'A18',
  ProxToHole175To200Rough = 'A19',
  ProxToHole200To225Rough = 'A20',
  ProxToHole225To250Rough = 'A21',
  ProxToHole250To275Rough = 'A22',
  ProxToHoleOver275Rough = 'A23',
  ProxToHoleFromFringe = 'A24',
  ProxToHoleFromRough = 'A25',
  ProxToHoleUnder10 = 'A26',
  ProxToHole10To20 = 'A27',
  ProxToHole20To30 = 'A28',
  ProxToHoleSand = 'A29',
  PuttingFromThree = 'A30',
  PuttingFromFour = 'A31',
  PuttingFromFive = 'A32',
  PuttsUnder6 = 'A33',
  PuttingFromSix = 'A34',
  PuttingFromSeven = 'A35',
  PuttingFromEight = 'A36',
  PuttingFromNine = 'A37',
  PuttingFromTen = 'A38',
  Putts7To11 = 'A39',
  Putts12To16 = 'A40',
  Putts17To21 = 'A41',
  PuttsOver21 = 'A42',
  ApproachPuttPerformance = 'A43',
  DrivingAccuracyCourse = 'A44',
  DrivingAccuracyRange = 'A45',
}
